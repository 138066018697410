@font-face {
  font-family: "Salesforce Sans";
  src: url("/src/SalesforceSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: "Salesforce Sans", Arial, sans-serif;
  margin: 0;
  padding: 0;
  font-size: calc(0.3vw+0.3vh+0.3vmin);
  background-color: #ffffff !important;
  min-height: 100vh;
}

body.dark-mode {
  background-color: #212121 !important;
}

.additional-highlight {
  background-color: rgba(255, 235, 59, 0.6) !important;
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}
button {
  margin-bottom: 1rem;
  background-color: #1976d2;
  color: #ffffff;
  border: 1px solid #1976d2;
  border-radius: 0.25rem;
  cursor: pointer;
  font-family: "Salesforce Sans", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  padding: 0.5rem 1rem;
  text-align: center;
  transition: all 0.1s linear;
}
button:hover {
  background-color: #1565c0;
  border-color: #1565c0;
}
.families {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
}
.family {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: 1px solid #e0e0e0;
  padding: 1rem;
  border-radius: 0.5rem;
  align-items: flex-start;
}
.family-name {
  font-weight: 600;
}
.dv-name {
  font-weight: 500;
  flex-grow: 1;
  padding-right: 1rem;
}
.tables {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-start;
}
.table {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: 1px solid #e0e0e0;
  padding: 0.5rem;
  border-radius: 0.5rem;
  table-layout: fixed;
  position: relative;
  transition: transform 0.3s ease;
}
.zoomed .table {
  transform: scale(0.5);
}
table {
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  border: 1px solid #e0e0e0;
  text-align: left;
  font-size: 0.7em;
}
table th,
table td {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  padding-top: 0.05rem;
  padding-bottom: 0.05rem;
}
thead {
  background-color: #f5f5f5;
}
table {
  width: 100%;
  border-collapse: collapse;
}
.toggles {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;
}
.toggles label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  user-select: none;
}
.toggles span {
  display: inline-block;
  vertical-align: middle;
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  vertical-align: middle;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  cursor: pointer;
}


.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #bdbdbd;
  transition: 0.2s;
  border-radius: 0.125rem; /* Add small border radius */
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.2s;
  border-radius: 0.125rem; /* Add small border radius */
}

input:checked + .slider {
  background-color: #1976d2;
}

input:checked + .slider:before {
  transform: translateX(20px);
}


.dark-mode {
  background-color: #212121;
  color: #f5f5f5;
}
.dark-mode .table,
.dark-mode th,
.dark-mode td {
  border-color: #757575;
}
.dark-mode thead {
  background-color: #424242;
}
.dark-mode .family,
.dark-mode .table {
  border-color: #757575;
}
.dark-mode .family {
  border-color: #757575;
}
.dark-mode .table {
  border-color: #757575;
}
.dark-mode thead {
  background-color: #616161;
}
.dark-mode th,
.dark-mode td {
  border-color: #757575;
}
.toggles span h2 {
  display: inline-block;
  height: 1.33em;
  line-height: 1.33em;
  margin: 0;
  vertical-align: middle;
}
.highlight-jobid {
  background-color: #ffc107;
}
.highlight-SubscriberID {
  background-color: #2196f3;
}
.highlight-SubscriberKey {
  background-color: #673ab7;
}
.highlight-TriggererSendDefinitionObjectID {
  background-color: #4caf50;
}
.highlight-VersionID {
  background-color: #f44336;
}
.highlight-AutomationCustomerKey {
  background-color: #ff4081;
}
.highlight-Mobile {
  background-color: #ff5722;
}
.highlight-ContactID {
  background-color: #ff9800;
}
.highlight-ContactKey {
  background-color: #8bc34a;
}
.highlight-_DeviceID {
  background-color: #03a9f4;
}
.dark-mode .highlight-jobid {
  background-color: #ffa000;
}
.dark-mode .highlight-SubscriberID {
  background-color: #1976d2;
}
.dark-mode .highlight-SubscriberKey {
  background-color: #512da8;
}
.dark-mode .highlight-TriggererSendDefinitionObjectID {
  background-color: #388e3c;
}
.dark-mode .highlight-VersionID {
  background-color: #d32f2f;
}
.dark-mode .highlight-AutomationCustomerKey {
  background-color: #f06292;
}
.dark-mode .highlight-Mobile {
  background-color: #e64a19;
}
.dark-mode .highlight-ContactID {
  background-color: #f57c00;
}
.dark-mode .highlight-ContactKey {
  background-color: #689f38;
}
.dark-mode .highlight-_DeviceID {
  background-color: #0288d1;
}
.highlight-JBactivityID {
  background-color: #c2185b;
}

.dark-mode .highlight-JBactivityID {
  background-color: #f48fb1;
}
.highlight-SMSJobID {
  background-color: #9c27b0; /* Purple */
}
.highlight-SMSBatchID {
  background-color: #00bcd4; /* Cyan */
}

.dark-mode .highlight-SMSJobID {
  background-color: #7b1fa2; /* Darker Purple */
}
.dark-mode .highlight-SMSBatchID {
  background-color: #0097a7; /* Darker Cyan */
}
.about-container {
  width: 100%;
}
.about-header {
  background-color: #f1f1f1;
  color: #444;
  cursor: pointer;
  padding: 10px;
  text-align: left;
  user-select: none;
  font-size: 1em;
}
.about-content p {
  padding-top: 20px;
  font-size: 1em;
}
.about-header:hover {
  background-color: #ccc;
}
.about-icon {
  display: inline-block;
  margin-right: 5px;
}
.dark-mode .about-container {
  color: #f5f5f5;
  background-color: #212121;
}
.dark-mode .about-content a {
  color: #ffc107;
}
.dark-mode .about-content a:hover {
  color: #f5f5f5;
}
.dark-mode .about-content {
  background-color: #212121;
}
.dark-mode .about-header {
  background-color: #333333;
  color: #f5f5f5;
}
.dark-mode .about-header:hover {
  background-color: #444444;
}
.about-margin-bottom {
  margin-bottom: 20px;
}
@media (min-width: 1024px) {
  .zoomed {
    max-width: 40%;
  }
  .table.zoomed p {
    width: fit-content;
  }
}
@media (max-width: 1023px) {
  .zoomed {
    width: 100%;
  }
  .table.zoomed {
    width: 100%;
    box-sizing: border-box;
  }
  .table.zoomed p {
    width: 100%;
  }
  .table.zoomed th,
  .table.zoomed td {
    font-size: 0.8em;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
  }
  .table td:first-child {
    min-width: 6rem;
  }
  .table td:nth-child(2) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .table td:last-child {
    word-wrap: break-word;
  }
}
.dark-mode .table {
  background-color: #212121;
}

.dark-mode .family {
  background-color: #333333;
}

.light-mode .table {
  background-color: #ffffff;
}

.light-mode .family {
  background-color: #f1f1f1;
}

.about-icon-container {
  display: inline-block;
  background-color: #f1f1f1;
  padding: 2px;
  border-radius: 2px;
}

.dark-mode .about-icon-container {
  background-color: #333333;
}
.about-icon-container {
  display: inline-block;
  background-color: #f1f1f1;
  padding: 2px;
  border-radius: 2px;
}

.dark-mode .about-icon-container {
  background-color: #333333;
}

.about-header:hover .about-icon-container {
  background-color: #ccc;
}

.dark-mode .about-header:hover .about-icon-container {
  background-color: #444444;
}

.light-comma {
  color: #ffffff;
}

.dark-mode .dark-comma {
  color: #212121;
}

.comma {
  color: transparent;
}

.search-box {
  position: relative;
  width: 200px;
}

.dropdown-list {
  position: absolute;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 10;
}

.dropdown-list li {
  padding: 5px;
  cursor: pointer;
}

.dropdown-list li:hover {
  background-color: #eee;
}

.toggles-container {
  min-width: 32vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.toggles {
  flex-shrink: 0;
}
*, *::before, *::after {
  box-sizing: border-box;
}

.search-box input[type="text"] {
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.25rem;
  font-family: "Salesforce Sans", Arial, sans-serif;
}

.dropdown-list {
  border-top: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 21rem;
  margin-top: -1px;
  
}

.dark-mode .search-box input[type="text"] {
  background-color: #333;
  border-color: #555;
  color: #f5f5f5;
}

.dark-mode .dropdown-list {
  background-color: #333;
  border-color: #555;
}

.dark-mode .dropdown-list li:hover {
  background-color: #444;
}

.search-box input[type="text"]::placeholder {
  color: #999;
}

.dark-mode .search-box input[type="text"]::placeholder {
  color: #aaa;
}

.dropdown-list li {
  font-family: "Salesforce Sans", Arial, sans-serif;
}

.search-input {
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.25rem;
  background-color: #ffffff;
  color: #000;
  font-family: "Salesforce Sans", Arial, sans-serif;
  transition: border-color 0.2s, box-shadow 0.2s;
  width: 21rem;
}

.search-input:focus {
  border-color: #1976d2;
  box-shadow: 0px 0px 5px rgba(25, 118, 210, 0.5);
  outline: none;
}

.search-input::placeholder {
  color: #999;
}

body.dark-mode .search-input {
  background-color: #333;
  border-color: #555;
  color: #f5f5f5;
}

body.dark-mode .search-input:focus {
  border-color: #1565c0;
  box-shadow: 0px 0px 5px rgba(21, 101, 192, 0.5);
}

.dropdown-list.hidden {
  display: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 5px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #f1f1f1;
}

body.dark-mode ::-webkit-scrollbar-track {
  background: #333;
}

body.dark-mode ::-webkit-scrollbar-thumb {
  background: #555;
}

body.dark-mode {
  scrollbar-color: #555 #333;
}

@media (max-width: 768px) {
  .toggles-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-box {
    width: 100%;
    margin-top: 1rem;
  }
  
  .toggles {
    width: 100%;
    justify-content: space-evenly;
  }
}

.clear-search {
  padding: 0;
  height: 2.15rem;
  width: 2.15rem;
  font-size: 1.5rem;
  line-height: 2rem;
  background-color: #1976d2;
  color: #ffffff;
  border: 1px solid #1976d2;
  border-radius: 0.25rem;
  cursor: pointer;
  font-family: "Salesforce Sans", Arial, sans-serif;
  text-align: center;
  transition: all 0.1s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto; 
}

.clear-search:hover {
  background-color: #1565c0;
  border-color: #1565c0;
}

body.dark-mode .clear-search {
  background-color: #424242;
  color: #f5f5f5;
  border-color: #424242;
}

body.dark-mode .clear-search:hover {
  background-color: #383838;
  border-color: #383838;
}

.search-container {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  justify-content: space-between; /* Adjust this as needed */
  width: 100%; /* Ensures the container takes full width */
}

#search-box {
  flex: 1;
  margin-right: 8px;
}

#clear-search {
  cursor: pointer;
}

.search-box-container {
  display: flex;
  position: relative;
  align-items: center;
  flex-grow: 1;
  padding-right: 10px;
}

/* Generate Query Button Styles */
.generate-query-button {
  margin-left: 1rem;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s;
}

.generate-query-button.disabled {
  background-color: #e0e0e0;
  color: #aaa;
  border-color: #ddd;
  cursor: not-allowed;
}

.dark-mode .generate-query-button {
  background-color: #424242;
  border-color: #616161;
}

.dark-mode .generate-query-button:hover {
  background-color: #333333;
  border-color: #505050;
}

.dark-mode .generate-query-button.disabled {
  background-color: #333333;
  color: #777;
  border-color: #505050;
}

/* Toast Notification Styles */
.toast-notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #1976d2;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
  animation: fadeinout 3s linear;
}

.dark-mode .toast-notification {
  background-color: #1565c0;
}

@keyframes fadeinout {
  0%, 100% { opacity: 0; }
  20%, 80% { opacity: 1; }
}

.disabled-button {
  background-color: #e0e0e0;
  color: #aaa;
  border-color: #ddd;
  cursor: not-allowed;
}

.dark-mode .disabled-button {
  background-color: #333333;
  color: #777;
  border-color: #505050;
}

.generate {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  justify-content: space-between; /* Adjust this as needed */
  width: 100%; /* Ensures the container takes full width */
  padding: 0.4rem 1rem !important;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-container {
  display: flex;
  align-items: center; /* Align items vertically */
  gap: 1rem; /* Adjust as needed to space out items */
}

/* CSS for desktop and larger screens */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.toggles-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* Mobile view adjustments for screens smaller than 768px */
@media (max-width: 960px) {
  .header-container {
    flex-direction: column; /* Stack elements vertically */
    align-items: stretch; /* Ensure elements take up the full width */
  }

  .toggles-container {
    flex-direction: column; /* Stack toggles and button vertically */
    align-items: flex-start; /* Align items to the start (left) */
  }

  .toggles {
    margin-bottom: 1rem; /* Add space between toggles and the button */
  }

  .generate {
    width: auto; /* Maintain the button's intrinsic width */
    align-self: flex-start; /* Align button to the start (left) */
  }

  .search-box-container {
    margin-bottom: 1rem; /* Add space between the search box and toggles */
  }
}

/* Additional adjustments for screens smaller than 540px */
@media (max-width: 830px) {
  .toggles {
    flex-direction: column; /* Stack toggles vertically */
    gap: 1rem; /* Add space between each toggle for better spacing */
  }
}

